"use strict";

document.addEventListener("DOMContentLoaded", function(){
    //dom is fully loaded, but maybe waiting on images & css files
    
    /* <div id="menu-hamburger" class="menu-hamburger menu-hamburger-unclicked" onclick="onMenuHamburgerClicked(this)"> */
    let elHamburger = document.getElementById("menu-hamburger");
    elHamburger.addEventListener("click", onMenuHamburgerClicked);
    // elHamburger.className += " menu-hamburger-unclicked";

});

function onMenuHamburgerClicked(el)
{   
    if (el.currentTarget.classList.contains("menu-hamburger-initialise") == true)
    {
        el.currentTarget.classList.remove("menu-hamburger-initialise");
        el.currentTarget.classList.add("menu-hamburger-clicked");
    }
    else
    {
        el.currentTarget.classList.toggle("menu-hamburger-clicked");
        el.currentTarget.classList.toggle("menu-hamburger-unclicked");
    }
}



// //toggle var
// var fade_state = true;
// //on btn click
// function fade() {
//   //get the button and div
//   let div = document.getElementById("div");
//   let btn = document.getElementById("fade");
//   //if faded in or out
//   if (fade_state == true) {
//     //trigers animation
//     div.style.animation = "fade-out 2s forwards";
//     //sets the text
//     btn.innerHTML = "fade-in";
//     //sets fade_state
//     fade_state = false;
//   } else if (fade_state == false) {
//     //trigers animation
//     div.style.animation = "fade-in 2s forwards";
//     //sets the text
//     btn.innerHTML = "fade-out";
//     //sets fade_state
//     fade_state = true;
//   }
// }